import React from "react";
import "../../styles/InfoCards.css";

function InfoCards() {
  return (
    <div>
      <div className="info_cards">
        <div className="cards--titles">
      <p className="exp">Registered brand</p>
        <p className="title--"> We make your way shine</p>
        </div>
        <div className="info_container">
          <div className="info__wrapper">
            <div className="info">
              <h2> High Quality Products </h2>
              <p>
                Since 2001, the company has been steadfast in maintaining the
                quality of its products, using superior quality raw materials in
                every stage of production.
              </p>
            </div>

            <div className="orange-card">
            <div className="info">
              <h2 style={{color: "#fff"}}>Unbreakable, Waterproof Luminaires </h2>
              <p style={{color: "#fff"}}>
                With over 21 years of experience in the field of manufacturing
                gate and garden lamp shades, the company is among the very first
                to manufacture gate lights using polycarbonate material thereby
                rendering it unbreakable. Hitherto, gate lights were largely
                made using acrylic or glass material. Thereby the brand
                Powerport is credited with revolutionising the industry by
                bringing in unbreakable gate lamp.
              </p>
              </div>
            </div>
            <div className="info">
              <h2> Wide Range of Designs </h2>
              <p>
                Powerport began with just two models of gate lamps and has now
                over 125 types of models under different categories such as gate
                lights, bollards and garden lamp shades.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
