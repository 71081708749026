import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../../styles/Contact.css";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ye2kkfn",
        "template_gtazdul",
        form.current,
        "-BLrD4h28D81bpyq1"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="contact_body">
      <div className="address">
        <h1 className="city">Chennai, India </h1>
        <p className="location">
          #42 (old #62), First floor, Defence Officers Colony, Second avenue,
          Ekkaduthangal, Chennai – 600032, Tamil Nadu, India{" "}
        </p>
        <p className="phone">
          +91 7358437748
          <br /> +91 9176073592
        </p>
        <p className="email">powerport31@gmail.com</p>
      </div>

      <form ref={form} onSubmit={sendEmail}>
        <div className="form">
          <div className="title">Welcome</div>
          <div className="subtitle"> Get in touch</div>
          <div className="input-container ic1">
            <input
              id="firstname"
              className="input"
              type="text"
              placeholder="your name"
              name="user_name"
              required
            />
          </div>
          <div className="input-container ic2">
            <input
              id="email"
              className="input"
              type="email"
              placeholder="example@gmail.com"
              name="user_email"
              required
            />
          </div>
          <div className="input-container ic2">
            <input
              id="subject"
              className="input"
              type="text"
              placeholder="subject"
              name="subject"
              required
            />
          </div>
          <div className="input-container ic2">
            <textarea
              id="message"
              className="textarea"
              type="text"
              placeholder="write your message..."
              name="message"
              required
            />
          </div>
          <button type="submit" className="submit">
            send
          </button>
        </div>
      </form>
    </div>
  );
}

export default Contact;
