import React from "react";
import Bollard from "./Bollard";
import Ellite from "./Ellite";
import GateLamp from "./GateLamp";
import "../../styles/Images.css";
import "../../styles/MobileImages.css";


function Images() {
  return (
    <div className="product-gallery">
      <GateLamp />
      <Bollard />
      <Ellite />
    </div>
  );
}

export default Images;