import React, { useState } from "react";
import "../../styles/AboutUS.css";

function AboutUS() {
  const [readMore, setReadMore] = useState(false);

  const extraContent = (
    <div>
      <p className="extra-content">
        the company is among the very first to manufacture gate lights using
        polycarbonate material thereby rendering it unbreakable. Hitherto, gate
        lights were largely made using acrylic or glass material. Thereby the
        brand Powerport is credited with revolutionising the industry by
        bringing in unbreakable gate lamp. Since 2001, the company has been
        steadfast in maintaining the quality of its products, using superior
        quality raw materials in every stage of production. The family-run
        company manufactures high quality bollard and gate light fittings and
        has a dedicated distribution network across South Indian states.
        Powerport began with just two models of gate lamps and has now over 125
        types of models under different categories such as gate lights, bollards
        and garden lamp shades. The brand has also recently forayed into the LED
        light manufacturing segment.
      </p>
    </div>
  );

  const linkName = readMore ? (
    <div></div>
  ) : (
    <button className="read_more">Read More</button>
  );
  return (
    <div>
      <div className="about__container">
        <div className="about__wrapper">
          <div className="about_pic">
            <img
              src="assets/about.jpg"
              alt=""
              width={"100%"}
              height={"150%"}
              className="about_pic__"
            />
          </div>
          <div className="about_info">
            <h1 className="about_head">About US</h1>
            <p className="about_content">
              Powerport Lighting Private Limited, a registered brand, was
              incorporated on April 10th, 2019. The company branched out from
              Powerport Enterprises, the latter of which has been in the
              industry of manufacturing gate and garden light fittings since the
              year 2001. With over 21 years of experience in the field of
              manufacturing gate and garden lamp shades,
              <br />
              <a
                href
                className=""
                onClick={() => {
                  setReadMore(!readMore);
                }}
              >
                {linkName}
              </a>
              {readMore && extraContent}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUS;
