import React from "react";

function Bollard() {
  return (
    <div>
      <h3
        style={{
          textAlign: "center",
          padding: "20px",
          color: "darkblue",
          fontFamily: "poppins",
        }}
        className="services-names"
      >
        Bollards
      </h3>
      <div className="images-grid-container_1">
        <div className="images-grid-wrap_1">
          <div className="images-grid_1">
            <img
              src="./assets/ledbollard_two.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>

          <div className="images-grid-container_2">
            <div className="images-grid-wrap_2">
              <div className="images-grid_2">
                <img
                  src="./assets/bollard_one.jpg"
                  alt=""
                  className="lamp-first-row"
                />
              </div>
              <div className="images-grid_2">
                <img
                  src="./assets/bollard_three.jpg"
                  alt=""
                  className="lamp-first-row"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bollard;
