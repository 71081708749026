import React from "react";
import CardItem from "./CardItem";
import "../../styles/Cards.css";

function Cards() {
  return (
    <div className="cards">
      <h1>Check out these EPIC Models!</h1>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items_1">
            <CardItem
              src="assets/gatelamp-1.jpg"
              label="Gate Lamps"
              text="A wide range of outdoor gate light fittings, compound wall lights, pillar lamps and luminaire made using high quality materials"
              path="/services"
            />
          </ul>

          <ul className="cards__items_1">
            <CardItem
              src="assets/bollard-1.jpg"
              label="Bollards"
              text="Classic and elegant light fittings to decorate and illuminate your gardens, lawns, pools and other outdoor spaces"
              path="/services"
            />
            <CardItem
              src="assets/elite-1.jpg"
              text="High quality  designs that are elite, minimalistic  and contemporary"
              label="Elite Services"
              path="/services"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
