import React from "react";

function GateLamp() {
  return (
    <div>
      <h3
        style={{
          textAlign: "start",
          padding: "20px",
          color: "darkblue",
        }}
      >
        Gate Lamps
      </h3>
      <div className="images-grid-container_1">
        <div className="images-grid-wrap_1">
          <div className="images-grid_1">
            <img
              src="./assets/gatelamp_two.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>




          <div className="images-grid-container_2">
        <div className="images-grid-wrap_2">
          <div className="images-grid_2">
            <img
              src="./assets/gatelamp_one.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>
          <div className="images-grid_2">
            <img
              src="./assets/gatelamp_three.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>
          <div className="images-grid_2">
            <img
              src="./assets/gatelamp_four.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>
          <div className="images-grid_1">
            <img
              src="./assets/gatelamp_five.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GateLamp;
