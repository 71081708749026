import React from "react";
import "../styles/Footer.css";
function Footer() {
  return (
    <>
      <div className="footer_container">
        <div className="footer__name">
          <div>
          <img
            src="./assets/logo-white-02.png"
            alt=""
            className="footer-logo"
          />
          </div>
          <br />
          {/* <small style={{ color: "gray" }} className="private_ltd">
            Lightning private limited
          </small> */}
        </div>

        <div className="address___footer">
          <p className="address_footer" style={{ color: "#E8E2E2" }}>
            #42 (old #62), First floor, Defence Officers Colony, Second avenue,
            Ekkaduthangal, Chennai – 600032rt
          </p>
        </div>
        <div className="social_media">
          <p className="social_icons">
            <p className="icns">Facebook</p>
            {/* <p className="icns">Twitter</p>
            <p className="icns">Instagram</p>
            <p className="icns">Youtube</p> */}
          </p>
        </div>
      </div>
      <p className="site__year">powerport 2023</p>
    </>
  );
}

export default Footer;
