import React from 'react'

function Ellite() {
  return (
    <div>
         <h3
        style={{
          textAlign: "end",
          padding: "20px",
          color: "darkblue",
        }}
      >
    Classic Gate Lamp
      </h3>
      <div className="images-grid-container_1">
        <div className="images-grid-wrap_1">
          <div className="images-grid_1">
            <img
              src="./assets/classic_gate_three.jpg"
              alt=""
              className="lamp-first-row"
            />
          </div>

          <div className="images-grid-container_2">
            <div className="images-grid-wrap_2">
              <div className="images-grid_2">
                <img
                  src="./assets/classic_gate_one.jpg"
                  alt=""
                  className="lamp-first-row"
                />
              </div>
              <div className="images-grid_2">
                <img
                  src="./assets/classic_gate_two.jpg"
                  alt=""
                  className="lamp-first-row"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ellite