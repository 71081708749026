import React from "react";
import "../../styles/Home.css";
import InfoCards from "./InfoCards";


function HomePage() {
  return (
    <div className="home__container">
      <div className="home__img__container">
        <img src="assets/home.jpg" alt="" className="home__img" />
      </div>
      <div className="centered caption-a">
        <p className="caption-a">Welcome To</p>
        <h1 className="caption-a">Powerport Lighting</h1>
      </div>
      <InfoCards />
    </div>
  );
}

export default HomePage;
