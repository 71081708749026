import { useRef } from "react";
import AboutUS from "./AboutUS";
import Cards from "./Cards";
import HomePage from "./HomePage";
import Contact from "./Contact";
import ScrolToTop from "./ScrolToTop";
import { Nav, Navbar, NavLink, Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Images from "./Images";
import "../../styles/Button.css";
import "../../App.css";

function ScrollNav() {
  const home = useRef(null);
  const about = useRef(null);
  const product = useRef(null);
  const contactlink = useRef(null);
  const contact = useRef(null);
  const gallery = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="App">
        <ScrolToTop />
        <div className="hero">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            fixed="top"
            className="bootstrap-nav"
          >
            <Container>
              <Navbar.Brand href="#">
                <div onClick={() => scrollToSection(home)}>
                  <img
                    src="./assets/logo-white-02.png"
                    alt=""
                    className="website-logo"
                  />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Nav.Link href="#home">
                    <NavLink>
                      <li
                        onClick={() => scrollToSection(home)}
                        className="link"
                      >
                        Home
                      </li>
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link eventKey={2}>
                    <NavLink>
                      <li
                        onClick={() => scrollToSection(about)}
                        className="link"
                      >
                        About
                      </li>
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link eventKey={2}>
                    <NavLink>
                      <li
                        onClick={() => scrollToSection(product)}
                        className="link"
                      >
                        Services
                      </li>
                    </NavLink>
                  </Nav.Link>
                  <Nav.Link eventKey={2}>
                    <Button
                      className="btn--outline"
                      onClick={() => scrollToSection(contact)}
                    >
                      contact
                    </Button>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
          <div ref={home} className="services">
            <HomePage />
          </div>
          <div ref={product} className="blog">
            <Cards />
          </div>
          <div ref={contactlink}>
            <div onClick={() => scrollToSection(contact)}>
              <p className="get-in-touch">
                To view the entire collection{" "}
                <span className="highlighted-text">get in touch with us</span>
              </p>
            </div>
          </div>
          <div ref={gallery} className="gallery">
            <Images />
          </div>
          <div ref={about}>
            <AboutUS />
          </div>
          <div ref={contact} className="contact">
            <Contact />
          </div>
      </div>
    </>
  );
}

export default ScrollNav;
